import React, { useState, useEffect } from "react";
import { m } from "framer-motion";
import useAnnouncementData from "@staticQueries/AnnouncementQuery";
import { Text, Icon, Container } from "@atoms";
import { AppLink } from "@base";

const Announcement = () => {
  const { enabled, heading, copy, button } = useAnnouncementData();
  const [show, setShowing] = useState(enabled);

  useEffect(() => {
    setTimeout(() => {
      const seen =
        window.localStorage.getItem("announcement") === heading + copy;
      if (seen) {
        setShowing(false);
      }
    }, 0);
  }, []);

  const variants = {
    show: {
      height: "auto",
      pointerEvents: "auto",
    },
    hide: {
      height: 0,
      pointerEvents: "none",
    },
  };

  return (
    <m.div
      initial="hide"
      variants={variants}
      animate={show ? "show" : "hide"}
      className="relative overflow-hidden bg-green pr-8 text-black md:pr-12"
    >
      <Container padding className="relative">
        <div className="flex flex-col gap-1 py-3 sm:flex-row sm:items-center sm:justify-between sm:gap-3">
          <div>
            <Text variant="h7">{heading}</Text>
            <Text className="text-[16px] font-medium leading-tight">
              {copy}
            </Text>
          </div>
          {button?.url && (
            <AppLink
              className="flex-shrink-0 text-[16px] font-medium underline underline-offset-4 transition duration-300 hover:text-green"
              to={button.url}
            >
              {button.text}
            </AppLink>
          )}
        </div>
      </Container>
      <button
        type="button"
        className="absolute inset-y-0 right-4 flex items-center justify-center"
        onClick={() => {
          window.localStorage.setItem("announcement", heading + copy);
          setShowing(false);
        }}
      >
        <span className="sr-only">close</span>
        <Icon name="close" className="h-4 w-4 text-black" />
      </button>
    </m.div>
  );
};

export default Announcement;
